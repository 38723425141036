import React from 'react';
import AdminLayout from '../../layouts/admin';

const iframe = '<iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%232e2e2e&amp;ctz=Europe%2FAmsterdam&amp;src=ZGpAaGl0c3RyZWFtLm5s&amp;src=bmwuZHV0Y2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%23039BE5&amp;color=%23009688&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showCalendars=0&amp;showTabs=1&amp;showDate=1&amp;mode=MONTH" style="border-width:0" width="100%" height="600" frameborder="0" scrolling="no"></iframe>';

const AgendaPage = ({ location }) => {
	return (
		<AdminLayout path={location.pathname} breadcrumbs={[{ link: '/admin/agenda', title: 'Agenda'}]}>
			<h1>
				Agenda
			</h1>
      <div dangerouslySetInnerHTML={{ __html: iframe }} />
		</AdminLayout>
	);
};

export default AgendaPage;